// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

// 字体符号
import 'font-awesome/css/font-awesome.min.css'

//1 eui组件
import ElementUI from 'element-ui';
// 2 eui 样式
import 'element-ui/lib/theme-chalk/index.css';

import VueClipboard from 'vue-clipboard2'
import * as filters from './filters'
import * as directives from './directives'
import components from './components'

import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store'

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)

// 全局组件
Object.keys(components).forEach(key => {
    Vue.component(key, components[key])
})

// 引入全局过滤器
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})

// 引入全局指令
Object.keys(directives).forEach(key => {
    Vue.directive(key, directives[key])
})

Vue.use(VueClipboard)
Vue.use(ElementUI)


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");